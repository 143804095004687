import React from "react";
import Box from "@mui/material/Box";
import "../style/base.css";
import WrapBoxTypo from "./BoxTypo";
import { Link } from "gatsby";

const ModalMenu = ({ text, link, marginNum, setOpen }) => {
  return (
    <Box my={marginNum}>
      <Link
        to={link}
        style={{ textDecoration: "none" }}
        onClick={() => setOpen((c) => !c)}
      >
        <WrapBoxTypo
          height="100%"
          display="flex"
          m={0}
          alignItems="center"
          justifyContent="center"
          color="#515D59"
        >
          {text}
        </WrapBoxTypo>
      </Link>
    </Box>
  );
};

export default ModalMenu;
