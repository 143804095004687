import React, { useState, useCallback, useEffect } from "react";
import Box from "@mui/material/Box";
import "../style/base.css";
import { Button, Modal } from "@mui/material";
import { StaticImage } from "gatsby-plugin-image";
import WrapBoxTypo from "./BoxTypo";
import { Link } from "gatsby";
import RightIcon from "./RightIcon";
import ModalMenu from "./ModalMenu";
import { useWindowSize } from "react-use";

const LinkArray = {
  top: "/",
  shopIndex: "/#store",
  catering: "/catering",
  recruit: "https://forustyle.com/recruit/",
  onlineShop: "https://forucafe.shop/",
  diagnosis: "/forugranola",
  forucafe: "https://forucafe.shop/",
  instagram: "https://www.instagram.com/forucafe/",
};

const Title = (props) => {
  return (
    <Box
      width="100%"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Link to={LinkArray.top} style={{ textDecoration: "none" }}>
        <WrapBoxTypo
          height="100%"
          display="flex"
          alignItems="center"
          justifyContent="center"
          color={
            props.isBlack
              ? "#303D38"
              : props.isChangeColor
              ? "#ffffff"
              : "#515D59"
          }
          fs="12px"
        >
          {props.title}
        </WrapBoxTypo>
      </Link>
    </Box>
  );
};

const DummyTitle = () => {
  return <Box width="100%"></Box>;
};

const Header = ({ isTop, isBlack, title }) => {
  const [isOpen, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  const { height } = useWindowSize();

  const [isChangeColor, setIsChangeColor] = useState(true);
  const [lastPosition, setLastPosition] = useState(0);
  const headerHeight = height;

  const scrollEvent = useCallback(() => {
    const offset = window.pageYOffset;
    if (offset > headerHeight) {
      setIsChangeColor(false);
    } else {
      setIsChangeColor(true);
    }
    setLastPosition(offset);
  }, [lastPosition]);

  useEffect(() => {
    window.addEventListener("scroll", scrollEvent);

    return () => {
      window.removeEventListener("scroll", scrollEvent);
    };
  }, [scrollEvent]);

  //ブラウザ上でリロードしたときフォントカラーが変わったため
  //下記useEffectで予防した
  useEffect(() => {
    const offset = window.pageYOffset;
    if (offset > headerHeight) {
      setIsChangeColor(false);
    } else {
      setIsChangeColor(true);
    }
  }, []);

  return (
    <Box>
      <Box sx={{ display: { xs: "block", md: "none" } }}>
        <Box
          zIndex={99}
          position="fixed"
          top="0"
          left="0"
          display="flex"
          justifyContent="center"
          alignItems="center"
          width="100%"
          padding="15px 20px 0"
        >
          <Box maxWidth={"49px"} maxHeight={"48px"}>
            {isBlack ? (
              <Link to={LinkArray.top}>
                <StaticImage
                  alt="img"
                  src="../images/forucafe-logo-black.png"
                  placeholder="blurred"
                  quality="100"
                />
              </Link>
            ) : isChangeColor ? (
              <Link to={LinkArray.top}>
                <StaticImage
                  alt="img"
                  src="../images/forucafe-logo-white.png"
                  placeholder="blurred"
                  quality="100"
                />
              </Link>
            ) : (
              <Link to={LinkArray.top}>
                <div>
                  <StaticImage
                    alt="img"
                    src="../images/forucafe-logo-main-color.png"
                    placeholder="blurred"
                    quality="100"
                  />
                </div>
              </Link>
            )}
          </Box>
          {!isTop ? (
            <>
              <Title
                title={title}
                isBlack={isBlack}
                isChangeColor={isChangeColor}
              />
            </>
          ) : (
            <>
              <DummyTitle />
            </>
          )}
          <Box>
            <Button
              style={{ padding: "0" }}
              onClick={() => {
                setOpen(true);
              }}
            >
              <Box maxWidth="22.45px" maxHeight="19px">
                {isBlack ? (
                  <StaticImage
                    alt="img"
                    src="../images/hamburger-bar-black.png"
                    placeholder="blurred"
                    quality="100"
                  />
                ) : isChangeColor ? (
                  <StaticImage
                    alt="img"
                    src="../images/hamburger-bar-white.png"
                    placeholder="blurred"
                    quality="100"
                  />
                ) : (
                  <StaticImage
                    alt="img"
                    src="../images/hamburger-bar-main-color.png"
                    placeholder="blurred"
                    quality="100"
                  />
                )}
              </Box>
            </Button>
          </Box>
        </Box>
        {!isOpen ? (
          <>
            <Box
              width="37px"
              zIndex={99}
              position="fixed"
              bottom="0"
              right="0"
              mb="60px"
              mr="20px"
            >
              <RightIcon>
                <Link to={LinkArray.diagnosis}>
                  <StaticImage
                    alt="img"
                    src="../images/diagnosis-circle-button.png"
                    placeholder="blurred"
                    quality="100"
                  />
                </Link>
              </RightIcon>
              <RightIcon>
                <Link to={LinkArray.onlineShop}>
                  <StaticImage
                    alt="img"
                    src="../images/cart-button.png"
                    placeholder="blurred"
                    quality="100"
                  />
                </Link>
              </RightIcon>
              <RightIcon>
                <Link to={LinkArray.instagram}>
                  <StaticImage
                    alt="img"
                    src="../images/instagram-button.png"
                    placeholder="blurred"
                    quality="100"
                  />
                </Link>
              </RightIcon>
            </Box>
          </>
        ) : (
          <></>
        )}

        <Modal
          open={isOpen}
          onClose={handleClose}
          style={{ outlineColor: "none", border: "none" }}
        >
          <Box
            height="70vh"
            width="100%"
            bgcolor="#F3F5F5"
            outlineColor="none"
            border="none"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Box border="none">
              <Box
                zIndex={9999}
                position="fixed"
                border="none"
                top="0"
                right="0"
                mt="18px"
                mr="10px"
              >
                <Button
                  style={{ padding: "0" }}
                  onClick={() => {
                    setOpen(false);
                  }}
                >
                  <Box maxWidth="44px" maxHeight="44px">
                    <StaticImage
                      alt="img"
                      src="../images/close-button.png"
                      placeholder="blurred"
                      quality="100"
                    />
                  </Box>
                </Button>
              </Box>
              <ModalMenu
                setOpen={setOpen}
                text="店舗一覧"
                marginNum="40px"
                link={LinkArray.shopIndex}
              />
              <ModalMenu
                setOpen={setOpen}
                text="ケータリング"
                marginNum="40px"
                link={LinkArray.catering}
              />
              <ModalMenu
                setOpen={setOpen}
                text="採用"
                marginNum="40px"
                link={LinkArray.recruit}
              />
              <Box maxWidth="300px" width={"70%"} m="auto" mb="20px">
                <Link to={LinkArray.instagram}>
                  <StaticImage
                    alt="img"
                    src="../images/instagram-forucafe-main-color.png"
                    placeholder="blurred"
                    quality="100"
                  />
                </Link>
              </Box>
              <Box display="flex" justifyContent="center">
                <Box mb="20px" maxWidth="300px" width={"70%"}>
                  <Link to={LinkArray.onlineShop}>
                    <StaticImage
                      alt="img"
                      src="../images/online-shop-button.png"
                      placeholder="blurred"
                      quality="100"
                    />
                  </Link>
                </Box>
              </Box>
              <Box display="flex" justifyContent="center">
                <Box mb="40px" maxWidth="300px" width={"70%"}>
                  <Link to={LinkArray.diagnosis}>
                    <StaticImage
                      alt="img"
                      src="../images/diagnosis-button.png"
                      placeholder="blurred"
                      quality="100"
                    />
                  </Link>
                </Box>
              </Box>
            </Box>
          </Box>
        </Modal>
      </Box>
      <Box sx={{ display: { xs: "none", md: "block" } }}>
        <Box
          width="100%"
          maxHeight="79px"
          mt={2}
          zIndex={99}
          position="fixed"
          top="0"
        >
          <Box width="80%" margin="auto" display="flex" justifyContent="center">
            <Box
              maxWidth={"1100px"}
              width={"100%"}
              display="flex"
              justifyContent="space-between"
              className="tac"
            >
              <Box maxWidth={"79.81px"} maxHeight={"79px"}>
                {isBlack ? (
                  <Link to={LinkArray.top}>
                    <StaticImage
                      alt="img"
                      src="../images/forucafe-logo-black.png"
                      placeholder="blurred"
                      quality="100"
                    />
                  </Link>
                ) : isChangeColor ? (
                  <Link to={LinkArray.top}>
                    <StaticImage
                      alt="img"
                      src="../images/forucafe-logo-white.png"
                      placeholder="blurred"
                      quality="100"
                    />
                  </Link>
                ) : (
                  <Link to={LinkArray.top}>
                    <StaticImage
                      alt="img"
                      src="../images/forucafe-logo-main-color.png"
                      placeholder="blurred"
                      quality="100"
                    />
                  </Link>
                )}
              </Box>
              <Box maxWidth={"670px"} width={"100%"} display={"flex"}>
                <Link
                  to={LinkArray.shopIndex}
                  style={{ textDecoration: "none" }}
                >
                  <WrapBoxTypo
                    height="100%"
                    mr="26px"
                    display="flex"
                    alignItems="center"
                    color={
                      isBlack
                        ? "#303D38"
                        : isChangeColor
                        ? "#ffffff"
                        : "#515D59"
                    }
                  >
                    店舗一覧
                  </WrapBoxTypo>
                </Link>
                <Link
                  to={LinkArray.catering}
                  style={{ textDecoration: "none" }}
                >
                  <WrapBoxTypo
                    height="100%"
                    mr="26px"
                    display="flex"
                    alignItems="center"
                    color={
                      isBlack
                        ? "#303D38"
                        : isChangeColor
                        ? "#ffffff"
                        : "#515D59"
                    }
                  >
                    ケータリング
                  </WrapBoxTypo>
                </Link>
                <Link to={LinkArray.recruit} style={{ textDecoration: "none" }}>
                  <WrapBoxTypo
                    height="100%"
                    mr="29px"
                    display="flex"
                    alignItems="center"
                    color={
                      isBlack
                        ? "#303D38"
                        : isChangeColor
                        ? "#ffffff"
                        : "#515D59"
                    }
                  >
                    採用
                  </WrapBoxTypo>
                </Link>
                <Box
                  mr="16px"
                  display="flex"
                  alignItems="center"
                  maxWidth={"166.31px"}
                  maxHeight={"100%"}
                  mt="5px"
                >
                  <Link to={LinkArray.onlineShop}>
                    <StaticImage
                      alt="img"
                      src="../images/online-shop-button.png"
                      placeholder="blurred"
                      quality="100"
                    />
                  </Link>
                </Box>
                <Box
                  display="flex"
                  alignItems="center"
                  maxWidth={"166.31px"}
                  maxHeight={"100%"}
                  mt="5px"
                >
                  <Link to={LinkArray.diagnosis}>
                    <StaticImage
                      alt="img"
                      src="../images/diagnosis-button.png"
                      placeholder="blurred"
                      quality="100"
                    />
                  </Link>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box zIndex={99} position="fixed" right="0" mt="70px" mr="30px">
          <Link to={LinkArray.forucafe} style={{ textDecoration: "none" }}>
            <WrapBoxTypo
              mb="63px"
              color={
                isBlack ? "#303D38" : isChangeColor ? "#ffffff" : "#515D59"
              }
              fs="14px"
              style={{ writingMode: "vertical-rl" }}
            >
              {isTop ? "forucafe" : title}
            </WrapBoxTypo>
          </Link>
          <Box
            display="flex"
            alignItems="center"
            margin="auto"
            maxWidth={"25px"}
            maxHeight={"27px"}
            mt="5px"
          >
            <Link to={LinkArray.instagram}>
              {isBlack ? (
                <StaticImage
                  alt="img"
                  src="../images/instagram-brands-black.png"
                  placeholder="blurred"
                  quality="100"
                />
              ) : isChangeColor ? (
                <StaticImage
                  alt="img"
                  src="../images/instagram-brands-white.png"
                  placeholder="blurred"
                  quality="100"
                />
              ) : (
                <StaticImage
                  alt="img"
                  src="../images/instagram-brands-main-color.png"
                  placeholder="blurred"
                  quality="100"
                />
              )}
            </Link>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Header;
