import React from "react";
import Box from "@mui/material/Box";
import "../style/base.css";
import { Link } from "gatsby";

const RightIcon = ({ children, link }) => {
  return (
    <Box width={"45px"} height={"45px"} mb="11px">
      <Link to={link}>{children}</Link>
    </Box>
  );
};

export default RightIcon;
