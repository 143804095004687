import * as React from "react";
import Box from "@mui/material/Box";
import "../style/base.css";
import WrapBoxTypo from "./BoxTypo";
import { Link } from "gatsby";
import Grid from "@mui/material/Grid";
import { StaticImage } from "gatsby-plugin-image";

const LogoArray = [
  {
    logoLink: "/",
    instagram: "https://www.instagram.com/forucafe/",
    onlineShop: "/online_shop",
  },
];

const ContentsArray = [
  {
    title: "店舗情報",
    subcontent: [
      { label: "本店", link: "/head-office" },
      { label: "原宿店", link: "/harajuku" },
      { label: "渋谷店", link: "/office-shibuya" },
      { label: "東京ワールドゲート店", link: "/office-tokyo-world" },
    ],
  },
  {
    title: "会社情報",
    subcontent: [
      { label: "会社概要", link: "https://forustyle.com/company/" },
      { label: "利用規約", link: "https://forucafe.shop/terms" },
      {
        label: "個人情報保護方針",
        link: "https://forucafe.shop/privacy_policy",
      },
    ],
  },
  {
    title: "お問い合わせ",
    subcontent: [
      { label: "採用情報", link: "https://forustyle.com/recruit/" },
      { label: "お問い合わせ", link: "https://forustyle.com/contact/" },
      { label: "info@forustyle.com", link: "", isMail: true },
    ],
  },
];

const Copywriter = (props) => {
  return (
    <>
      <WrapBoxTypo color="#DCDFDE" fs="10px">
        {props.copywriter}
      </WrapBoxTypo>
    </>
  );
};

const ContentTitle = (props) => {
  return (
    <>
      <Box sx={{ pl: ["9px", "0"] }}>
        <WrapBoxTypo color="#ffffff" fs="14px" lh="150%">
          {props.title}
        </WrapBoxTypo>
      </Box>
      <Box sx={{ borderBottom: 1, borderColor: "#747D7A" }} mb="14px" />
    </>
  );
};

const Content = (props) => {
  const regex = /^https:\/\//;
  return (
    <Box mb="12px" sx={{ pl: ["9px", "0"] }}>
      {props.isMail ? (
        <WrapBoxTypo
          color="#ffffff"
          fs="12px"
          lh="150%"
          ls={props.isMail && "0"}
          style={{ overflowWrap: "break-word" }}
        >
          <Box display="flex">
            {props.isMail && (
              <Box maxWidth={"18px"} maxHeight={"18px"} mr="6px">
                <StaticImage
                  alt="img"
                  src="../images/mail.png"
                  placeholder="blurred"
                  quality="100"
                />
              </Box>
            )}
            {props.title}
          </Box>
        </WrapBoxTypo>
      ) : (
        <Link to={props.link} style={{ textDecoration: "none" }}>
          <WrapBoxTypo
            color="#ffffff"
            fs="12px"
            lh="150%"
            ls={props.isMail && "0"}
            style={{ overflowWrap: "break-word" }}
          >
            <Box display="flex">
              {props.isMail && (
                <Box maxWidth={"18px"} maxHeight={"18px"} mr="6px">
                  <StaticImage
                    alt="img"
                    src="../images/mail.png"
                    placeholder="blurred"
                    quality="100"
                  />
                </Box>
              )}
              {props.title}
            </Box>
          </WrapBoxTypo>
        </Link>
      )}
    </Box>
  );
};

const Footer = () => {
  return (
    <>
      <Box
        width="100%"
        bgcolor="#515D59"
        sx={{ pt: ["26px", "41px"], pb: ["16px", "28px"] }}
      >
        <Box maxWidth="1000px" width={"85%"} margin="auto">
          <Grid
            container
            width={"100%"}
            justifyContent="space-around"
            rowSpacing={1}
            columnSpacing={{ xs: 1, sm: 2, md: 3 }}
          >
            <Grid item xs={12} sm={3}>
              <Box
                maxWidth="95px"
                margin="auto"
                sx={{
                  maxWidth: ["200px", "95px"],
                  display: ["flex", "block"],
                  justifyContent: "space-between",
                }}
              >
                <Box
                  maxWidth={"95px"}
                  maxHeight={"96px"}
                  sx={{ mb: ["24px"] }}
                  textAlign="left"
                >
                  <Link to={LogoArray[0].logoLink}>
                    <StaticImage
                      alt="img"
                      src="../images/forucafe-logo-white.png"
                      placeholder="blurred"
                      quality="100"
                    />
                  </Link>
                </Box>
                <Box
                  display="flex"
                  justifyContent="space-around"
                  alignItems="center"
                  sx={{ mb: ["12px"], width: ["50%", "100%"] }}
                >
                  <Box maxWidth={"22px"} maxHeight={"24px"}>
                    <Link to={LogoArray[0].instagram}>
                      <StaticImage
                        alt="img"
                        src="../images/instagram-brands-white.png"
                        placeholder="blurred"
                        quality="100"
                      />
                    </Link>
                  </Box>
                  <Box maxWidth={"20px"} maxHeight={"19px"}>
                    <Link to={LogoArray[0].onlineShop}>
                      <StaticImage
                        alt="img"
                        src="../images/cart.png"
                        placeholder="blurred"
                        quality="100"
                      />
                    </Link>
                  </Box>
                </Box>
              </Box>
              <Box sx={{ display: { xs: "none", sm: "block" } }}>
                <Box display="flex" justifyContent="center">
                  <Copywriter copywriter="&copy; 2022 forucafe" />
                </Box>
              </Box>
            </Grid>
            {ContentsArray.map((e) => {
              const content = e.subcontent.map((e1) => {
                return (
                  <Content title={e1.label} link={e1.link} isMail={e1.isMail} />
                );
              });
              return (
                <Grid item xs={12} sm={3}>
                  <Box
                    margin="auto"
                    sx={{ mb: ["50px", "0"], maxWidth: ["250px", "157px"] }}
                  >
                    <ContentTitle title={e.title} />
                    {content}
                  </Box>
                </Grid>
              );
            })}
          </Grid>
        </Box>
      </Box>
      <Box
        width="100%"
        bgcolor="#515D59"
        sx={{ display: { xs: "block", sm: "none" } }}
        pb="16px"
      >
        <Box display="flex" justifyContent="center">
          <Copywriter
            copywriter="&copy; 2022 forucafe"
            style={{ color: "#fff" }}
          />
        </Box>
      </Box>
    </>
  );
};

export default Footer;
